$footerWidth: 1260px;
$footerHeight: 120px;
.footer {
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
}

.container {
  width: $footerWidth;
  height: 100%;
  display: flex;
  align-items: center;
  margin: 20px 4px;
  @media (min-width: 1900px) {
    width: 1600px;
  }
}

.wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.column {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.title {
  text-align: center;
  font-size: 24px;
  white-space: nowrap;
  @media (min-width: 1900px) {
    font-size: 30px;
  }
  @media (max-width: 1500px) {
    font-size: 22px;
  }
  @media (max-width: 1360px) {
    font-size: 20px;
  }
  @media (max-width: 1024px) {
    font-size: 18px;
    white-space: normal;
  }
  @media (max-width: 768px) {
    font-size: 17px;
  }
  @media (max-width: 600px) {
    font-size: 16px;
  }
  @media (max-width: 500px) {
    font-size: 15px;
  }
  @media (max-width: 400px) {
    font-size: 13px;
  }
}

.text {
  text-align: center;
  font-size: 20px;
  margin-top: 16px;
  white-space: nowrap;
  @media (min-width: 1900px) {
    font-size: 26px;
  }
  @media (max-width: 1500px) {
    font-size: 18px;
  }
  @media (max-width: 1360px) {
    font-size: 16px;
  }
  @media (max-width: 1024px) {
    white-space: normal;
    font-size: 14px;
  }
  @media (max-width: 768px) {
    font-size: 12px;
  }
  @media (max-width: 600px) {
    font-size: 11px;
  }
  @media (max-width: 500px) {
    font-size: 10px;
  }
  @media (max-width: 400px) {
    font-size: 9px;
  }
}

.br {
  display: none;
  @media (max-width: 800px) {
    display: block;
  }
}
