.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url("../../assets/images/back.jpg");
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  @media (min-width: 1900px) {
    height: 70vh;
  }
  @media (max-width: 1900px) {
    height: 80vh;
  }
  @media (min-width: 1900px) {
    height: 80vh;
  }
  @media (max-width: 1360px) {
    height: 64vh;
  }
  @media (max-width: 768px) {
    height: 50vh;
  }
}

.servicesInfo {
  width: 100%;
  max-width: 1260px;
  @media (min-width: 1900px) {
    max-width: 1660px;
  }
  @media (max-width: 1024px) {
    max-width: 720px;
  }
}

.services {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: row;
  @media (max-width: 1024px) {
    justify-content: space-evenly;
  }
}

.service {
  display: flex;
  width: 25%;
  text-align: center;
  padding: 8px;
  flex-direction: column;
  align-items: center;
}

.title {
  color: #f4f5f6;
  font-size: 48px;
  padding-left: 16px;
  @media (min-width: 2000px) {
    font-size: 66px;
    padding-left: 32px;
  }
  @media (max-width: 1500px) {
    font-size: 44px;
    padding-left: 12px;
  }
  @media (max-width: 1360px) {
    font-size: 40px;
    padding-left: 8px;
  }
  @media (max-width: 1024px) {
    text-align: center;
    font-size: 36px;
    padding-left: 6px;
  }
  @media (max-width: 875px) {
    font-size: 32px;
    padding-left: 4px;
  }
  @media (max-width: 768px) {
    font-size: 29px;
    padding-left: 4px;
  }
  @media (max-width: 500px) {
    font-size: 28px;
    padding-left: 4px;
  }
  @media (max-width: 400px) {
    font-size: 26px;
    padding-left: 4px;
  }
}

.number {
  color: #6b59cc;
  font-size: 92px;
  font-weight: 600;
  @media (min-width: 2000px) {
    font-size: 112px;
  }
  @media (max-width: 1500px) {
    font-size: 88px;
  }
  @media (max-width: 1360px) {
    font-size: 84px;
  }
  @media (max-width: 1024px) {
    font-size: 80px;
  }
  @media (max-width: 875px) {
    font-size: 70px;
  }
  @media (max-width: 768px) {
    font-size: 58px;
  }
  @media (max-width: 500px) {
    font-size: 50px;
  }
  @media (max-width: 400px) {
    font-size: 40px;
  }
}

.text {
  color: #ffffff;
  font-size: 32px;
  font-weight: 600;
  @media (min-width: 2000px) {
    font-size: 42px;
  }
  @media (max-width: 1500px) {
    font-size: 28px;
  }
  @media (max-width: 1360px) {
    font-size: 24px;
  }
  @media (max-width: 1024px) {
    font-size: 22px;
  }
  @media (max-width: 875px) {
    font-size: 20px;
  }
  @media (max-width: 768px) {
    font-size: 17px;
  }
  @media (max-width: 500px) {
    font-size: 16px;
  }
  @media (max-width: 400px) {
    font-size: 15px;
  }
}
