.container {
  background-image: url("../../assets/images/intro.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  @media (min-width: 1900px) {
    height: 80vh;
  }
  @media (max-width: 1900px) {
    height: 80vh;
  }
  @media (max-width: 1360px) {
    height: 60vh;
  }
  @media (max-width: 768px) {
    height: 40vh;
    background-image: none;
    align-items: center;
    background: #695bba;
  }
}

.textBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title {
  width: 600px;
  text-align: center;
  hyphens: auto;
  color: #0e3fa0;
  font-size: 42px;
  position: relative;
  left: 150px;
  @media (min-width: 2000px) {
    left: 180px;
    font-size: 58px;
    width: 800px;
  }
  @media (max-width: 1500px) {
    left: 80px;
    font-size: 38px;
    width: 500px;
  }
  @media (max-width: 1360px) {
    left: 30px;
    font-size: 34px;
  }
  @media (max-width: 1024px) {
    left: 20px;
    font-size: 30px;
    width: 400px;
  }
  @media (max-width: 875px) {
    left: 10px;
    font-size: 26px;
    width: 350px;
  }
  @media (max-width: 768px) {
    font-size: 26px;
    color: #fff;
  }
  @media (max-width: 500px) {
    font-size: 24px;
  }
  @media (max-width: 400px) {
    font-size: 16px;
    width: 200px;
  }
}

.subtitle {
  width: 600px;
  text-align: center;
  hyphens: auto;
  color: #6985bd;
  font-size: 32px;
  position: relative;
  top: 30px;
  left: 150px;
  @media (min-width: 2000px) {
    left: 180px;
    font-size: 48px;
    width: 800px;
  }
  @media (max-width: 1500px) {
    top: 25px;
    left: 80px;
    font-size: 28px;
    width: 500px;
  }
  @media (max-width: 1360px) {
    top: 20px;
    left: 30px;
    font-size: 24px;
  }
  @media (max-width: 1024px) {
    left: 20px;
    font-size: 20px;
    width: 400px;
  }
  @media (max-width: 875px) {
    left: 10px;
    font-size: 16px;
    width: 340px;
  }
  @media (max-width: 768px) {
    font-size: 16px;
    width: 300px;
    color: #fff;
  }
  @media (max-width: 500px) {
    font-size: 14px;
  }
  @media (max-width: 400px) {
    font-size: 12px;
  }
  @media (max-width: 400px) {
    font-size: 11px;
    width: 200px;
  }
}
