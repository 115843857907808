.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 1260px;
  width: 100%;
  margin: 32px 0;
  @media (min-width: 2000px) {
    margin: 40px 0;
  }
  @media (max-width: 1500px) {
    margin: 30px 0;
  }
  @media (max-width: 1360px) {
    margin: 28px 0;
  }
  @media (max-width: 1024px) {
    margin: 24px 0;
  }
  @media (max-width: 875px) {
    margin: 20px 0;
  }
}

.main {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.title {
  font-size: 42px;
  font-weight: 600;
  text-align: center;
  @media (min-width: 2000px) {
    font-size: 60px;
  }
  @media (max-width: 1500px) {
    font-size: 40px;
  }
  @media (max-width: 1360px) {
    font-size: 38px;
  }
  @media (max-width: 1024px) {
    font-size: 34px;
  }
  @media (max-width: 875px) {
    font-size: 32px;
  }
  @media (max-width: 768px) {
    font-size: 30px;
  }
  @media (max-width: 560px) {
    font-size: 28px;
  }
  @media (max-width: 500px) {
    font-size: 26px;
  }
  @media (max-width: 450px) {
    font-size: 24px;
  }
  @media (max-width: 400px) {
    font-size: 22px;
  }
}

.line {
  background: #45405d;
  height: 2px;
  width: 120px;
  margin-top: 10px;
  margin-bottom: 48px;
  @media (min-width: 2000px) {
    width: 200px;
    margin-top: 16px;
    margin-bottom: 64px;
  }
  @media (max-width: 1500px) {
    width: 110px;
    margin-top: 8px;
    margin-bottom: 44px;
  }
  @media (max-width: 1360px) {
    width: 100px;
    margin-top: 6px;
    margin-bottom: 40px;
  }
  @media (max-width: 1024px) {
    width: 90px;
    margin-top: 4px;
    margin-bottom: 36px;
    height: 1px;
  }
  @media (max-width: 875px) {
    width: 70px;
    margin-top: 2px;
    margin-bottom: 30px;
    height: 1px;
  }
}

.flexTable {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
  @media (min-width: 2000px) {
    gap: 28px;
    margin-bottom: 28px;
  }
  @media (max-width: 1500px) {
    gap: 18px;
    margin-bottom: 18px;
  }
  @media (max-width: 1360px) {
    gap: 16px;
    margin-bottom: 16px;
  }
  @media (max-width: 1024px) {
    gap: 14px;
    margin-bottom: 14px;
  }
  @media (max-width: 875px) {
    gap: 12px;
    margin-bottom: 12px;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 220px;
  width: 240px;
  background: #45405d;
  padding: 10px;
  border-radius: 14px;
  @media (min-width: 2000px) {
    height: 300px;
    width: 340px;
    padding: 16px;
    border-radius: 18px;
  }
  @media (max-width: 1500px) {
    height: 200px;
    width: 220px;
    padding: 8px;
    border-radius: 12px;
  }
  @media (max-width: 1360px) {
    height: 180px;
    width: 200px;
    padding: 6px;
    border-radius: 10px;
  }
  @media (max-width: 875px) {
    height: 170px;
    width: 190px;
    padding: 6px;
    border-radius: 10px;
  }
}

.cardTitle {
  display: flex;
  justify-content: space-between;
}

.cardImage {
  margin-left: 8px;
}

.cardIcon {
  height: 70px;
  width: 70px;
  @media (min-width: 2000px) {
    height: 94px;
    width: 94px;
  }
  @media (max-width: 1500px) {
    height: 64px;
    width: 64px;
  }
  @media (max-width: 1360px) {
    height: 56px;
    width: 56px;
  }
  @media (max-width: 875px) {
    height: 52px;
    width: 52px;
  }
}

.cardNumber {
  display: flex;
  justify-content: end;
  font-size: 24px;
  font-weight: 600;
  color: #ffd700;
  width: 120px;
  padding-bottom: 12px;
  border-bottom: 2px solid #ffd700;
  @media (min-width: 2000px) {
    width: 200px;
    font-size: 36px;
    padding-bottom: 20px;
  }
  @media (max-width: 1500px) {
    width: 110px;
    font-size: 22px;
    padding-bottom: 10px;
  }
  @media (max-width: 1360px) {
    width: 100px;
    font-size: 20px;
    padding-bottom: 8px;
  }
  @media (max-width: 875px) {
    width: 90px;
    font-size: 18px;
    padding-bottom: 6px;
  }
}

.text {
  height: 60px;
  text-align: center;
  font-size: 16px;
  color: rgb(240, 240, 240);
  @media (min-width: 2000px) {
    height: 80px;
    font-size: 24px;
  }
  @media (max-width: 1500px) {
    height: 50px;
    font-size: 15px;
  }
  @media (max-width: 1360px) {
    height: 40px;
    font-size: 14px;
  }
  @media (max-width: 875px) {
    height: 40px;
    font-size: 13px;
  }
}

.answer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  background: #382a51;
  border-radius: 8px;
  box-shadow:
    1px 1px rgb(91, 91, 91),
    0px 0 5px rgb(154, 154, 153);
  padding: 4px;
  text-align: center;
  font-size: 16px;
  color: rgb(240, 240, 240);
  @media (min-width: 2000px) {
    height: 60px;
    font-size: 22px;
  }
  @media (max-width: 1500px) {
    height: 40px;
    font-size: 14px;
  }
  @media (max-width: 1360px) {
    height: 35px;
    font-size: 12px;
  }
  @media (max-width: 875px) {
    height: 40px;
    font-size: 11px;
  }
}

.twoCard {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
  width: 520px;
  padding: 10px;
  background: #45405d;
  border-radius: 14px;
  @media (min-width: 2000px) {
    height: 300px;
    width: 740px;
    padding: 16px;
    border-radius: 18px;
  }
  @media (max-width: 1500px) {
    height: 200px;
    width: 480px;
    padding: 8px;
    border-radius: 12px;
  }
  @media (max-width: 1360px) {
    height: 180px;
    width: 428px;
    padding: 6px;
    border-radius: 10px;
  }
  @media (max-width: 875px) {
    height: 174px;
    width: 408px;
    padding: 4px;
    border-radius: 10px;
  }
  @media (max-width: 500px) {
    height: 170px;
    width: 190px;
    padding: 6px;
    border-radius: 10px;
  }
}

.block {
  display: flex;
  justify-content: center;
  align-items: center;
}

.call {
  width: 80px;
  height: 80px;
  @media (min-width: 2000px) {
    width: 120px;
    height: 120px;
  }
  @media (max-width: 1500px) {
    width: 74px;
    height: 74px;
  }
  @media (max-width: 1360px) {
    width: 68px;
    height: 68px;
  }
  @media (max-width: 500px) {
    display: none;
  }
}

.cardText {
  font-size: 38px;
  color: #fbdf3e;
  margin-left: 16px;
  @media (min-width: 2000px) {
    margin-left: 18px;
    font-size: 54px;
  }
  @media (max-width: 1500px) {
    margin-left: 14px;
    font-size: 34px;
  }
  @media (max-width: 1360px) {
    margin-left: 12px;
    font-size: 30px;
  }
  @media (max-width: 500px) {
    text-align: center;
    font-size: 26px;
    margin-left: 0px;
  }
}
