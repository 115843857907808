.container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0px;
}

.photosBlock {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 16px;
  @media (min-width: 2000px) {
    gap: 28px;
  }
  @media (max-width: 2000px) {
    gap: 16px;
  }
  @media (max-width: 1360px) {
    gap: 14px;
  }
  @media (max-width: 1120px) {
    gap: 12px;
  }
  @media (max-width: 875px) {
    gap: 10px;
  }
  @media (max-width: 768px) {
    gap: 8px;
  }
  @media (max-width: 560px) {
    gap: 6px;
  }
  @media (max-width: 430px) {
    gap: 4px;
  }
}

.photo {
  @media (min-width: 2000px) {
    width: 600px;
    height: 600px;
  }
  @media (max-width: 2000px) {
    width: 520px;
    height: 520px;
  }
  @media (max-width: 1360px) {
    width: 420px;
    height: 420px;
  }
  @media (max-width: 1120px) {
    width: 380px;
    height: 380px;
  }
  @media (max-width: 1024px) {
    width: 340px;
    height: 340px;
  }
  @media (max-width: 875px) {
    width: 320px;
    height: 320px;
  }
  @media (max-width: 768px) {
    width: 280px;
    height: 280px;
  }
  @media (max-width: 660px) {
    width: 260px;
    height: 260px;
  }
  @media (max-width: 560px) {
    width: 220px;
    height: 220px;
  }
  @media (max-width: 460px) {
    width: 200px;
    height: 200px;
  }
  @media (max-width: 430px) {
    width: 160px;
    height: 160px;
  }
  @media (max-width: 350px) {
    width: 140px;
    height: 140px;
  }
}
