.container {
  background-color: #f0f3f7;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2vw 20vw 4vw 20vw;
  @media (max-width: 768px) {
    padding: 2vw 14vw 4vw 14vw;
  }
}

.main {
  background-color: #fff;
  padding: 2vw;
}

.mainTitle {
  font-size: 42px;
  @media (min-width: 2000px) {
    font-size: 52px;
  }
  @media (max-width: 1500px) {
    font-size: 38px;
  }
  @media (max-width: 1360px) {
    font-size: 36px;
  }
  @media (max-width: 1024px) {
    font-size: 34px;
  }
  @media (max-width: 875px) {
    font-size: 30px;
  }
  @media (max-width: 768px) {
    font-size: 28px;
  }
  @media (max-width: 560px) {
    font-size: 26px;
  }
  @media (max-width: 460px) {
    font-size: 22px;
  }
}

.mainText {
  font-size: 22px;
  @media (min-width: 2000px) {
    font-size: 32px;
  }
  @media (max-width: 1500px) {
    font-size: 21px;
  }
  @media (max-width: 1360px) {
    font-size: 20px;
  }
  @media (max-width: 1024px) {
    font-size: 18px;
  }
  @media (max-width: 875px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 560px) {
    font-size: 13px;
  }
  @media (max-width: 460px) {
    font-size: 12px;
  }
}

.title {
  font-size: 32px;
  font-weight: 700;
  padding-top: 12px;
  @media (min-width: 2000px) {
    font-size: 42px;
    padding-top: 22px;
  }
  @media (max-width: 1500px) {
    font-size: 30px;
  }
  @media (max-width: 1360px) {
    font-size: 28px;
    padding-top: 10px;
  }
  @media (max-width: 1024px) {
    font-size: 26px;
  }
  @media (max-width: 875px) {
    font-size: 24px;
    padding-top: 8px;
  }
  @media (max-width: 768px) {
    font-size: 22px;
  }
  @media (max-width: 560px) {
    font-size: 20px;
    padding-top: 6px;
  }
  @media (max-width: 460px) {
    font-size: 18px;
  }
}

.text {
  font-size: 22px;
  @media (min-width: 2000px) {
    font-size: 32px;
  }
  @media (max-width: 1500px) {
    font-size: 21px;
  }
  @media (max-width: 1360px) {
    font-size: 20px;
  }
  @media (max-width: 1024px) {
    font-size: 18px;
  }
  @media (max-width: 875px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
  }
  @media (max-width: 560px) {
    font-size: 13px;
  }
  @media (max-width: 460px) {
    font-size: 12px;
  }
}
