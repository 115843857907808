$headerHeight: 70px;
$headerWidth: 1260px;

.header {
  display: flex;
  justify-content: center;
  width: 100%;
  position: fixed;
  background-color: white;
  z-index: 100;
}

.navigation {
  width: 100%;
  max-width: $headerWidth;
  max-height: $headerHeight;
  padding: 0px 90px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (min-width: 2000px) {
    max-width: 1660px;
    max-height: 90px;
  }
  @media (max-width: 1500px) {
    padding: 0px 84px;
  }
  @media (max-width: 1360px) {
    padding: 0px 76px;
  }
  @media (max-width: 1024px) {
    padding: 0px 70px;
  }
  @media (max-width: 875px) {
    padding: 0px 64px;
  }
  @media (max-width: 768px) {
    max-width: 90vw;
    padding: 0px 6px;
  }
}

.logo {
  height: 70px;
  @media (min-width: 2000px) {
    height: 90px;
  }
  @media (max-width: 1500px) {
    height: 64px;
  }
  @media (max-width: 1360px) {
    height: 56px;
  }
  @media (max-width: 1120px) {
    height: 50px;
  }
  @media (max-width: 1024px) {
    height: 44px;
  }
  @media (max-width: 875px) {
    height: 36px;
  }
  @media (max-width: 768px) {
    height: 34px;
  }
  @media (max-width: 560px) {
    height: 32px;
  }
  @media (max-width: 460px) {
    height: 30px;
  }
}

.navInfo {
  display: flex;
  align-items: center;
  justify-content: center;
}

.phone {
  text-decoration: none;
  color: black;
  font-weight: 600;
  padding-left: 0px 16px;
  font-size: 22px;
  @media (min-width: 2000px) {
    padding-left: 20px;
    font-size: 28px;
  }
  @media (max-width: 1500px) {
    padding-left: 14px;
    font-size: 20px;
  }
  @media (max-width: 1360px) {
    padding-left: 12px;
    font-size: 18px;
  }
  @media (max-width: 1120px) {
    padding-left: 10px;
    font-size: 16px;
  }
  @media (max-width: 1024px) {
    padding-left: 6px;
    font-size: 13px;
  }
  @media (max-width: 875px) {
    font-size: 11px;
    padding-left: 4px;
  }
  @media (max-width: 768px) {
    font-size: 18px;
    padding: 10px 10px;
  }
  @media (max-width: 560px) {
    font-size: 14px;
    padding: 8px 8px;
  }
  @media (max-width: 460px) {
    font-size: 12px;
    padding: 6px 7px;
  }
}

.phoneWrap {
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }
  @media (max-width: 375px) {
    display: none;
  }
}

.phoneIcon {
  height: 38px;
  width: 38px;
  @media (max-width: 768px) {
    height: 30px;
    width: 30px;
  }
  @media (max-width: 560px) {
    height: 30px;
    width: 30px;
  }
  @media (max-width: 460px) {
    height: 30px;
    width: 30px;
  }
}

.image {
  @media (max-width: 768px) {
    height: 32px;
    width: 32px;
  }
  @media (max-width: 560px) {
    height: 28px;
    width: 28px;
  }
  @media (max-width: 460px) {
    height: 24px;
    width: 24px;
  }
}

.link {
  text-decoration: none;
  color: #6b59cc;
  padding-left: 28px;
  font-size: 21px;
  @media (min-width: 2000px) {
    padding-left: 38px;
    font-size: 28px;
  }
  @media (max-width: 1500px) {
    padding-left: 28px;
    font-size: 21px;
  }
  @media (max-width: 1360px) {
    padding-left: 23px;
    font-size: 18px;
  }
  @media (max-width: 1120px) {
    padding-left: 18px;
    font-size: 17px;
  }
  @media (max-width: 1024px) {
    padding-left: 14px;
    font-size: 15px;
  }
  @media (max-width: 875px) {
    padding-left: 12px;
    font-size: 13px;
  }
}
