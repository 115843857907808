.popper {
  width: 30vw;
  height: 180px;
  background-color: white;
  z-index: 99;
  padding: 6px 16px 0 12px;
  z-index: 100;
  @media (max-width: 600px) {
    height: 170px;
  }
  @media (max-width: 500px) {
    height: 156px;
  }
  @media (max-width: 400px) {
    height: 146px;
  }
}

.viewBurgerIcon {
  height: 34px;
  display: flex;
  @media (max-width: 460px) {
    height: 30px;
  }
  @media (max-width: 425px) {
    height: 28px;
  }
}

.hideBurgerIcon {
  display: none;
}

.link {
  text-decoration: none;
  color: #6b59cc;
  font-size: 18px;
  @media (max-width: 600px) {
    font-size: 16px;
  }
  @media (max-width: 500px) {
    font-size: 14px;
  }
  @media (max-width: 400px) {
    font-size: 12px;
  }
}
