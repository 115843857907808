$containerWidth: 1260px;

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vh 2vw;
}

.line {
  background: #6b59cc;
  height: 2px;
  width: 120px;
  margin-top: 10px;
  margin-bottom: 16px;
  @media (min-width: 2000px) {
    width: 200px;
    margin-top: 16px;
    margin-bottom: 64px;
  }
  @media (max-width: 1500px) {
    width: 110px;
    margin-top: 8px;
    margin-bottom: 14px;
  }
  @media (max-width: 1360px) {
    width: 100px;
    margin-top: 6px;
    margin-bottom: 12px;
  }
  @media (max-width: 1024px) {
    width: 90px;
    margin-top: 4px;
    margin-bottom: 10px;
    height: 1px;
  }
  @media (max-width: 875px) {
    width: 70px;
    margin-top: 2px;
    margin-bottom: 8px;
    height: 1px;
  }
}

.contacts {
  width: 100%;
  max-width: $containerWidth;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  @media (min-width: 2000px) {
    max-width: 1660px;
  }
  @media (max-width: 1024px) {
    max-width: 600px;
    justify-content: space-evenly;
  }
}

.contactBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.title {
  font-size: 42px;
  font-weight: 600;
  @media (min-width: 2000px) {
    font-size: 60px;
  }
  @media (max-width: 1500px) {
    font-size: 40px;
  }
  @media (max-width: 1360px) {
    font-size: 38px;
  }
  @media (max-width: 1024px) {
    font-size: 34px;
  }
  @media (max-width: 875px) {
    font-size: 30px;
  }
  @media (max-width: 768px) {
    font-size: 26px;
  }
  @media (max-width: 600px) {
    font-size: 23px;
  }
  @media (max-width: 500px) {
    font-size: 22px;
  }
  @media (max-width: 400px) {
    font-size: 20px;
  }
}

.contactsButton {
  width: 100px;
  height: 100px;
  @media (min-width: 2000px) {
    width: 124px;
    height: 124px;
  }
  @media (max-width: 1500px) {
    width: 90px;
    height: 90px;
  }
  @media (max-width: 1360px) {
    width: 80px;
    height: 80px;
  }
  @media (max-width: 875px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 768px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 600px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 500px) {
    width: 70px;
    height: 70px;
  }
  @media (max-width: 400px) {
    width: 70px;
    height: 70px;
  }
}

.image {
  width: 80px;
  height: 80px;
  @media (min-width: 2000px) {
    width: 104px;
    height: 104px;
  }
  @media (max-width: 1500px) {
    width: 74px;
    height: 74px;
  }
  @media (max-width: 1360px) {
    width: 68px;
    height: 68px;
  }
  @media (max-width: 875px) {
    width: 60px;
    height: 60px;
  }
  @media (max-width: 768px) {
    width: 54px;
    height: 54px;
  }
  @media (max-width: 600px) {
    width: 48px;
    height: 48px;
  }
  @media (max-width: 500px) {
    width: 40px;
    height: 40px;
  }
  @media (max-width: 400px) {
    width: 30px;
    height: 30px;
  }
}

.text {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  padding-top: 16px;
  color: #444343;
  @media (min-width: 2000px) {
    padding-top: 18px;
    font-size: 28px;
  }
  @media (max-width: 1500px) {
    font-size: 17px;
    padding-top: 14px;
  }
  @media (max-width: 1360px) {
    font-size: 16px;
    padding-top: 12px;
  }
  @media (max-width: 875px) {
    font-size: 15px;
    padding-top: 10px;
  }
  @media (max-width: 768px) {
    font-size: 14px;
    padding-top: 8px;
  }
  @media (max-width: 600px) {
    font-size: 13px;
    padding-top: 7px;
  }
  @media (max-width: 500px) {
    font-size: 12px;
    padding-top: 6px;
  }
  @media (max-width: 400px) {
    font-size: 11px;
    padding-top: 4px;
  }
}

.map {
  @media (min-width: 2000px) {
    width: 80vw;
    height: 40vh;
  }
  @media (max-width: 2000px) {
    width: 80vw;
    height: 40vh;
  }
  @media (max-width: 1360px) {
    width: 80vw;
    height: 36vh;
  }
  @media (max-width: 1024px) {
    width: 80vw;
    height: 30vh;
  }
}
